(function ($) {
  $(document).ready(function () {
    // $('a[href^="#"]:not(a.gnavi-trigger,#back_to_top a)').on("click", function (
    //   e
    // ) {
    //   var $href = $(this).attr("href"),
    //     $tgt = $($href == "#" || $href == "" ? "html" : $href),
    //     $pos = $tgt.offset().top,
    //     $correctH = 65;

    //   if (window.innerWidth > 1000) {
    //     if ($(".navigation__fix").hasClass("fixed")) {
    //       $correctH = 90;
    //     } else {
    //       $correctH = 200;
    //     }
    //   }

    //   $("html, body").animate(
    //     {
    //       scrollTop: $pos - $correctH,
    //     },
    //     500
    //   );
    //   e.preventDefault();
    // });

    /*var mql = window.matchMedia("(min-width : 1000px)");
    mql.addListener(gnaviClose);
    gnaviClose(mql);

    function gnaviClose(mql) {
      if (mql.matches) {
        $("#gnavi").removeClass('gnavi_open');
        $('.gnavi-trigger').removeClass('triggeractive');
      }
    }*/

    /*$(".slider").slick({
      arrows: false,
      autoplay: true,
      fade: true,
      speed: 1500,
      pauseOnHover: false,
    });*/

    // gmap iframe
    $(".gmap").click(function () {
      $(".gmap iframe").css("pointer-events", "auto");
    });
  });
})(jQuery);

window.addEventListener("DOMContentLoaded", function () {
  //set noopener

  var $links = document.querySelectorAll('a[target="_blank"]');
  [].forEach.call($links, function (link) {
    if (link.hasAttribute("rel") === false) {
      link.setAttribute("rel", "noopener");
    }
  });

  //hmb

  function gnavi() {
    const toggle = document.getElementById("toggle");

    if (!toggle) {
      return;
    }

    const gnavi = document.getElementById("gnavi");
    const body = document.querySelector("body");

    toggle.addEventListener("click", function (e) {
      gnavi.style.transition = "all 0.5s ease-in-out";
      e.preventDefault();
      body.classList.toggle("gnavi-open");
    });

    window.addEventListener("resize", function () {
      if (window.innerWidth >= 970) {
        gnavi.style.transition = "none";
        body.classList.remove("gnavi-open");
      }
    });

    /*gnavi.addEventListener("click", function (e) {
      if (window.innerWidth < 768) {
        e.preventDefault();
        body.classList.remove("gnavi-open");
      }
    });*/

    const gnaviWrap = document.querySelector("#gnavi .gnavi-wrap");

    /*gnaviWrap.addEventListener("click", function (e) {
      e.stopPropagation();
    });*/
  }

  gnavi();

  objectFitImages(".ofi");

  function linkInSite() {
    const hash = location.hash;
    if (hash !== "") {
      window.scrollBy(0, -100);
    }
  }
  window.addEventListener("load", linkInSite);
});

window.addEventListener("load", function () {
  //const loading = document.querySelector("#loading");
  //loading.classList.add("finished");
});

/******************
 *
 * add sns gnavi
 *
 * ***************/

class addSnsLink {
  constructor(el, tgt) {
    this.el = document.querySelector(el);
    this.tgt = document.querySelector(tgt);
    this._init = window.addEventListener(
      "DOMContentLoaded",
      this._init.bind(this)
    );
  }
  _init() {
    if (this.el && this.tgt) {
      const tgtHtml = this.tgt.innerHTML;
      const li = document.createElement("li");
      li.classList.add("sns");
      li.innerHTML = tgtHtml;
      this.el.appendChild(li);
    }
  }
}
new addSnsLink("#gnavi .gnavi-wrap", "#js-sns");

/******************
 *
 * add tel
 *
 * ***************/

class addTel {
  constructor(el, tgt) {
    this.el = document.querySelector(el);
    this.tgt = document.querySelector(tgt);
    this._init = window.addEventListener(
      "DOMContentLoaded",
      this._init.bind(this)
    );
  }
  _init() {
    if (this.el) {
      const tgtHtml = this.tgt.innerHTML;
      const li = document.createElement("li");
      li.classList.add("sns");
      li.innerHTML = tgtHtml;
      this.el.appendChild(li);
    }
  }
}
//new addTel("#gnavi .gnavi-wrap", "#js-tel");

/******************
 *
 * firstview scroll
 *
 * ***************/
import smoothscroll from "smoothscroll-polyfill";

smoothscroll.polyfill();
class SmoothScroll {
  constructor(els) {
    this.els = document.querySelectorAll(els);
    this.init = window.addEventListener(
      "DOMContentLoaded",
      this._init.bind(this)
    );
  }

  _init() {
    if (!this.els) {
      return;
    }
    [...this.els].forEach((el) => {
      el.addEventListener("click", (e) => {
        e.preventDefault();
        const targetId = el.hash;
        const tgtPosition =
          document.querySelector(targetId).getBoundingClientRect().top +
          window.pageYOffset;
        let headerHeight;
        if (window.innerWidth >= 970) {
          headerHeight = 100;
        } else if (window.innerWidth >= 768) {
          headerHeight = 72;
        } else {
          headerHeight = 55;
        }

        const scrollAmount = tgtPosition - headerHeight;

        window.scrollTo({ top: scrollAmount, behavior: "smooth" });
      });
    });
  }
}

new SmoothScroll('a[href^="#"]');

/******************
 *
 * slider
 *
 * ***************/

class Slider {
  constructor(el, options) {
    this.el = document.querySelector(el);
    this.options = options;
    window.addEventListener("DOMContentLoaded", this._init.bind(this));
  }
  _init() {
    if (this.el !== null) {
      new Swiper(this.el, this.options);
    }
  }
}

// new Slider(".main-slider", {
//   autoplay: {
//     delay: 3000
//   },
//   loop: true,
//   speed: 1000,
//   effect: "fade"
// });

class BlogSlider {
  constructor(el, options) {
    this.el = document.querySelector(el);
    this.options = options;
    window.addEventListener("DOMContentLoaded", this._init.bind(this));
  }
  _init() {
    if (!this.el) {
      return;
    }

    let children = this.el.querySelectorAll(".swiper-slide");

    if (children.length > 1) {
      new Swiper(this.el, this.options);
    }
  }
}

new BlogSlider(".top-blog-slider", {
  autoplay: {
    delay: 3000,
  },
  loop: true,
  speed: 1000,
  slidesPerView: 1.5,
  spaceBetween: 20,
  centeredSlides: true,
  breakpoints: {
    768: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 30,
    },
  },
});

/******************
 *
 * header fix
 *
 * ***************/

function fixAction() {
  const navigation = document.querySelector(".navigation");
  const fixedBnr = document.querySelector(".fixed-bnr");
  let scr;
  let tgtPosition;

  function addFixed() {
    scr = window.pageYOffset;
    tgtPosition = 300;

    if (window.innerWidth >= 768) {
      if (scr > tgtPosition) {
        navigation.classList.add("fixed");
        //fixedBnr.classList.add("fixed");
      } else {
        navigation.classList.remove("fixed");
        //fixedBnr.classList.remove("fixed");
      }
    }
  }
  addFixed();

  window.addEventListener("scroll", addFixed);
  window.addEventListener("load", addFixed);
}
fixAction();

/**************
 *
 * parallax
 *
 * ********** */

function parallax() {
  const parallax = document.querySelectorAll(".parallax");

  const options = {
    root: null,
    rootMargin: "100px  0px -100px",
    transhold: 0,
  };

  const observer = new IntersectionObserver(doWhenIntersect, options);

  [...parallax].forEach((elm) => {
    observer.observe(elm);
  });

  function doWhenIntersect(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("parallaxActive");
      } else {
        entry.target.classList.remove("parallaxActive");
      }
    });
  }
}
parallax();

/**************
 *
 * Top で　gnavi のブログが選択状態になっているのを弾く
 *
 * ********** */

class rmBlogClass {
  constructor() {
    this.body = document.body;
    this._init = window.addEventListener(
      "DOMContentLoaded",
      this._init.bind(this)
    );
  }

  _init() {
    if (this.body.classList.contains("home")) {
      this.body.classList.remove("blog");
    }
  }
}
new rmBlogClass();

/****************
 *
 *
 * menu pageの補足が空白のところを削除
 *
 * ************* */

class removeMenuSplBlank {
  constructor(els) {
    this.els = document.querySelectorAll(els);
    this._init = window.addEventListener(
      "DOMContentLoaded",
      this._init.bind(this)
    );
  }

  _init() {
    if (!this.els) {
      return;
    }
    [...this.els].forEach((el) => {
      let html = el.innerHTML.trim();
      if (html === "") {
        el.parentNode.removeChild(el);
      }
    });
  }
}
new removeMenuSplBlank(".menu-price__spl");
